import React from "react";
import BodyContainer from "../components/BodyContainer";

const Home = () => {
    return (
        <BodyContainer>
            <div className="lot-main-section-01-bg-web"></div>
        </BodyContainer>
    );
};

export default Home;
