import React from "react";
import BodyContainer from "../components/BodyContainer";

const TermsOfService = () => {
    return (
        <BodyContainer forceToShowLogo>
            <div className="lot-term-container-web">
                <div className="lot-term-title-tx">이용약관</div>
                <div className="lot-term-content-tx">
                    <div className="flex">
                        <div className="mr-2">1. </div>
                        <div>
                            개요 본 이용약관(이하 “약관”)은 ‘롯뜨다 롯뜨!’을 사용하는 모든
                            사용자(이하 “사용자”)와 회사 이름 간의 법적 계약을 의미합니다. 앱을
                            다운로드하고 사용함으로써 본 약관에 동의하게 됩니다. <br />
                            <br />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mr-2">2. </div>
                        <div>
                            서비스 제공 ‘롯뜨다 롯뜨!’은 사용자에게 다음 기능을 제공합니다.
                            <br /> - 소셜 로그인 기능 (Google, Apple 등)
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mr-2">3. </div>
                        <div>
                            사용자는 앱을 합법적인 목적으로만 사용해야 하며, 불법적이거나 부적절한
                            콘텐츠를 업로드하거나 배포해서는 안 됩니다. 사용자는 본인의 계정을
                            안전하게 관리할 책임이 있습니다.
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mr-2">4. </div>
                        <div>
                            권한 및 데이터 사용
                            <br /> - 소셜 로그인 : 소셜 미디어 계정을 통해 로그인이 가능합니다. 이를
                            통해 이메일 주소 등을 수집하며, 해당 정보는 사용자 인증을 위한
                            목적으로만 사용됩니다.
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mr-2">5. </div>
                        <div>
                            회사는 서비스 사용 중 발생하는 직간접적인 손해에 대해 책임지지 않습니다.
                            사용자는 자신의 데이터 백업을 책임지며, 앱 사용 중 발생할 수 있는 데이터
                            손실에 대해 회사는 책임지지 않습니다.
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mr-2">6. </div>
                        <div>
                            회사는 언제든지 사전 공지 없이 앱의 기능을 변경하거나 중단할 수
                            있습니다. 사용자에게는 서비스 중단으로 인해 발생한 손해에 대한 보상을
                            요구할 권리가 없습니다.
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mr-2">7. </div>
                        <div>
                            분쟁 해결 본 약관은 대한민국 법률에 따라 해석되며, 이에 관한 모든 분쟁은
                            관할 법원에서 해결됩니다.
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </BodyContainer>
    );
};

export default TermsOfService;
