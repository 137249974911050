import React from "react";
import { Link } from "react-router-dom";
import LogoImg from "../assets/logo.png";

const Header = ({ forceToShowLogo = false }) => {
    return (
        <div className="lot-header-container">
            <div className="lot-header-background"></div>
            <div className="lot-header-content">
                <Link to="/">
                    <div className="flex gap-[8px] items-center">
                        {forceToShowLogo && (
                            <img src={LogoImg} alt="사각 로고" className="w-[30px]" />
                        )}
                        <div className="lot-app-title-web">롯뜨다 롯뜨!</div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Header;
