import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const BodyContainer = ({ children, forceToShowLogo }) => {
    return (
        <div className="flex flex-col w-full min-h-screen overflow-hidden">
            <Header forceToShowLogo={forceToShowLogo} />
            <div className="flex-1">{children}</div>
            <Footer />
        </div>
    );
};

export default BodyContainer;
