import React from "react";
import { Link } from "react-router-dom";
import { constant } from "../constants/constants";

const Footer = () => {
    return (
        <div className="lot-footer-container">
            <a href={constant.AmuzHomePage}>
                <div className="lot-footer-amuz-name-tx">(주)아뮤즈</div>
            </a>
            <div className="lot-footer-term-box">
                <Link to="/terms" className="lot-footer-pp-tx">
                    이용약관
                </Link>
                <Link to="/privacy" className="lot-footer-ts-tx">
                    개인정보처리방침
                </Link>
            </div>
            <div className="lot-footer-company-info-box">
                대표 : 이우진 | 사업자 등록번호 : 871-81-00755 <br />
                주소 : 부산광역시 부산진구 중앙대로 623, 3~4F <br />
                이메일 : amuz@amuz.co.kr | 대표전화 : 02.1833.5770 | 팩스 : 070.7575.3852
            </div>
        </div>
    );
};

export default Footer;
